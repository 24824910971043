import React from 'react'
import Navbar from '../components/navbar'
import ServicesOne from '../components/ServiceTwo/secone'
import Sectiontwo from '../components/ServiceTwo/sectwo'

import Footer from '../components/footer'

export default function serviceone() {
  return (
    <div>
        <Navbar/>
        <ServicesOne/>
        <Sectiontwo/>
        {/* <Sectionthree/> */}
        <Footer/>
    </div>
  )
}
